@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: "cera round pro";
  font-weight: 900;
  src: url(./fonts/cera-round-pro/CeraRoundProBlack.otf);
}
@font-face {
  font-family: "cera round pro";
  font-weight: 700;
  src: url(./fonts/cera-round-pro/CeraRoundProBold.otf);
}
@font-face {
  font-family: "cera round pro";
  font-weight: 500;
  src: url(./fonts/cera-round-pro/CeraRoundProMedium.otf);
}
@font-face {
  font-family: "cera round pro";
  font-weight: 400;
  src: url(./fonts/cera-round-pro/CeraRoundProRegular.otf);
}
@font-face {
  font-family: "cera round pro";
  font-weight: 300;
  src: url(./fonts/cera-round-pro/CeraRoundProLight.otf);
}
@font-face {
  font-family: "cera round pro";
  font-weight: 100;
  src: url(./fonts/cera-round-pro/CeraRoundProThin.otf);
}

*{
  box-sizing: border-box;
  margin:0;
}

